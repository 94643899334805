import { Model } from '@app/Model.js';
import * as prismic from '@prismicio/client';
import moment from 'moment-timezone';
import 'moment/dist/locale/de.js';
import { Log } from '../app/Log.js';

export const recursiveJSONProxy = (parent) => {
	const proxy = new Proxy(parent, {
		get: (target, prop) => {
			if (
				// lit pollutes Objects with undefined properties; exclude these cases
				typeof prop === 'string' &&
				!prop.startsWith('_$lit') &&
				prop !== 'nodeType' &&
				(typeof target[prop] === 'undefined' ||
					typeof target[prop] === 'object')
			) {
				const objectChainAsString = `${target.__path || ''}.${prop}`;
				let fallback;

				if (typeof target[prop] === 'undefined') {
					window.Log.error(
						new Error(`Object chain undefined: ${objectChainAsString}`),
					);
					// eslint-disable-next-line no-new-wrappers
					fallback = new String(objectChainAsString);
					fallback.__path = objectChainAsString;
					fallback.toString = () => objectChainAsString;
					fallback.valueOf = () => objectChainAsString;
					fallback.toSource = () => objectChainAsString;
				} else {
					fallback = {
						...target[prop],
						__path: objectChainAsString,
					};
				}
				return recursiveJSONProxy(fallback);
			}
			return target[prop];
		},
	});
	return proxy;
};

export default class Localisation {
	async fetchContent() {
		try {
			this.translations[this.language] = await import(
				`./${this.language}.json`
			);
			const prismicLanguage = this.language === 'de' ? 'de' : 'en';
			const {
				quick_tip: prismicQuickTips,
				information_card: prismicInfoCards,
				descriptions: prismicDescriptions,
				faq_group: prismicFaqGroups,
				worth_knowing: prismicWorthKnowings,
				insights: prismicInsights,
				news: prismicNews,
				yd_training: prismicTrainings,
			} = await import(`./${prismicLanguage}.prismic.ts`);

			this.prismicContent = {
				quickTips: Object.assign(
					{},
					...prismicQuickTips.map(({ uid, data }) => ({
						[uid]: data,
					})),
				),
				informationCard: Object.assign(
					{},
					...prismicInfoCards.map(({ uid, data }) => ({
						[uid]: data,
					})),
				),
				descriptions: Object.assign(
					{},
					...prismicDescriptions.map(({ uid, data }) => ({
						[uid]: Object.assign(
							{},
							...data.pair.map(
								({ key, title, description, public: visible }) => ({
									[key]: { title, description, visible },
								}),
							),
						),
					})),
				),
				faqGroup: Object.assign(
					{},
					...prismicFaqGroups.map(({ uid, data }) => ({
						[uid]: data,
					})),
				),
				worthKnowings: Object.assign(
					{},
					...prismicWorthKnowings.map(({ uid, data }) => ({
						[uid]: data,
					})),
				),
				insights: prismicInsights.map(({ data }) => data),
				news: prismicNews.map(({ data, uid, last_publication_date: date }) => ({
					...data,
					date,
					uid,
				})),
				trainings: prismicTrainings.map(
					({ data, last_publication_date: date }) => ({
						...data,
						date,
					}),
				),
			};
		} catch (err) {
			Log.error(err);
		}
	}

	constructor(initialLanguage = 'en') {
		this.translations = {
			de: null,
			en: null,
		};
		this.language = this.isSupported(initialLanguage) ? initialLanguage : 'en';
		moment.locale(this.language);
		this.txt = {};
		this.prismicClient = prismic.createClient('ymmd');
		this.prismicContent = {};
	}

	isSupported(language) {
		return Object.keys(this.translations).find((l) => l === language);
	}

	async setLanguage(language = this.language) {
		let languageShort = language.substring(0, 2);
		if (!this.isSupported(languageShort)) {
			languageShort = 'en';
		}
		const isNew = this.language !== languageShort;
		this.language = languageShort;

		await this.fetchContent();
		moment.locale(this.language);
		this.txt = recursiveJSONProxy(this.translations[this.language]);

		if (window.appElement && isNew) {
			// eslint-disable-next-line require-atomic-updates
			window.appElement.statics = await Model.statics(true);
			// eslint-disable-next-line require-atomic-updates
			window.T = this.txt;
			// eslint-disable-next-line require-atomic-updates
			window.prismicContent = this.prismicContent;
			window.appElement.dispatchEvent(new CustomEvent('rerender'));
		}
	}
}
