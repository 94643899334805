import { Router } from '../../router';
import { commonDefaultRoute, importPage } from '../../router/defaultroute';
import { checkAuth } from '../../router/routeBeforeHooks';
import { confirmNavigation } from '../../router/routeLeaveHooks';
import { getRouteFromMatch } from '../../router/utils/getRouteFromMatch';
import { isUpdateAvailable } from '../../router/utils/isUpdateAvailable';
import { socket } from '../Socket.js';
import { openHelpForm } from './modal/help.js';
import {
	canAccessWithoutOnboarding,
	canAccessWithoutVerifiedEmail,
	isSalesTool,
	isSalesToolAdmin,
} from './routeBeforeHooks';

const talentDefaultRoute: TalentRoute = {
	...commonDefaultRoute,
	isAllowedWithoutOnboarding: false,
	isAllowedWithoutVerifiedEmail: false,
	hasPageTracking: false,
	hasRocketchat: false,
	hooks: {
		...commonDefaultRoute.hooks,
		before: async (done: (arg1: boolean) => void, match: Match) => {
			if (isUpdateAvailable()) {
				done(false);
				window.location.href = `${document.baseURI}${match.url}`;
				return;
			}

			if (isUpdateAvailable()) {
				done(false);
				window.location.href = `${document.baseURI}${match.url}`;
				return;
			}

			const { ydRoute, query } = getRouteFromMatch(match);

			const canNavigate =
				ydRoute &&
				canAccessWithoutVerifiedEmail(ydRoute) &&
				canAccessWithoutOnboarding(ydRoute) &&
				checkAuth(ydRoute);

			if (canNavigate) {
				// "preload" to abort the navigation in case the imported code errors
				ydRoute.templateClass = await importPage(ydRoute, query);
			}

			done(canNavigate && !!ydRoute.templateClass);
		},
	},
};

export const guiRoutes: { [path: string]: TalentRoute } = {
	'/contract/:contractId/:userId/:dpaStatus': {
		...talentDefaultRoute,
		as: 'contract',
		hasRocketchat: true,
	},
	'/contract/:contractId/:userId': {
		...talentDefaultRoute,
		as: 'contract',
		hasRocketchat: true,
	},
	'/contract/:contractId': {
		...talentDefaultRoute,
		as: 'contract',
		hasRocketchat: true,
	},
	'/contract': {
		...talentDefaultRoute,
		as: 'contract',
		hasRocketchat: true,
	},
	'/learning': {
		...talentDefaultRoute,
		as: 'learning',
		isComponent: true,
	},
	'/monitor': {
		...talentDefaultRoute,
		as: 'monitor',
		isComponent: true,
	},
	'/join/worthknowing/:topic': {
		...talentDefaultRoute,
		as: 'join/worthknowing',
		isAllowedWithoutOnboarding: true,
		hasPageTracking: true,
		isComponent: true,
	},
	'/join': {
		...talentDefaultRoute,
		as: 'join',
		isAllowedWithoutOnboarding: true,
		hasPageTracking: true,
	},
	'/join/:page/:topic': {
		...talentDefaultRoute,
		as: 'join',
		isAllowedWithoutOnboarding: true,
		hasPageTracking: true,
	},
	'/join/:page': {
		...talentDefaultRoute,
		as: 'join',
		isAllowedWithoutOnboarding: true,
		hasPageTracking: true,
	},
	'/join/:page/:companyId/:moduleId': {
		...talentDefaultRoute,
		as: 'join',
		isAllowedWithoutOnboarding: true,
		hasPageTracking: true,
	},
	'/teams': {
		...talentDefaultRoute,
		as: 'teams',
		isComponent: true,
		hasRocketchat: true,
	},
	'/signdoc/:doc/:userId/:moduleId': {
		...talentDefaultRoute,
		as: 'signdoc',
		isComponent: true,
		hasRocketchat: true,
	},
	'/profile/:group': {
		...talentDefaultRoute,
		as: 'profile',
		isComponent: true,
		hasRocketchat: true,
	},
	'/profile': {
		...talentDefaultRoute,
		as: 'profile',
		isComponent: true,
		hasRocketchat: true,
	},
	'/projectoffer/:companyId/:moduleId': {
		...talentDefaultRoute,
		as: 'projectoffer',
		hooks: {
			...talentDefaultRoute.hooks,
			leave: (done) =>
				confirmNavigation(
					done,
					window.T.jobapplication.leave_page_message,
					window.T.jobapplication.leave_page,
				),
		},
		hasRocketchat: true,
	},
	'/projectofferinfo/:companyId/:moduleId': {
		...commonDefaultRoute,
		as: 'projectofferinfo',
		isComponent: true,
		hasRocketchat: true,
	},
	'/trainings': {
		...talentDefaultRoute,
		as: 'trainings',
		isComponent: true,
		hasRocketchat: true,
	},
	'/account/:settingsgroup': {
		...talentDefaultRoute,
		as: 'account',
		isAllowedWithoutOnboarding: true,
		isAllowedWithoutVerifiedEmail: true,
		hasPageTracking: true,
		isComponent: true,
		hasCaptcha: true,
		hasRocketchat: true,
	},
	'/account': {
		...talentDefaultRoute,
		as: 'account',
		isAllowedWithoutOnboarding: true,
		isAllowedWithoutVerifiedEmail: true,
		hasPageTracking: true,
		isComponent: true,
		hasCaptcha: true,
		hasRocketchat: true,
	},
	'/chat': {
		...talentDefaultRoute,
		as: 'chat',
	},
	'/jobs/:settingsgroup': {
		...talentDefaultRoute,
		as: 'jobs',
		isComponent: true,
	},
	'/jobs': {
		...talentDefaultRoute,
		as: 'jobs',
		isComponent: true,
		hasRocketchat: true,
	},
	'/onboarding/:step': {
		...talentDefaultRoute,
		as: 'onboarding',
		isAllowedWithoutOnboarding: true,
		hasPageTracking: true,
		isComponent: true,
	},
	'/onboarding': {
		...talentDefaultRoute,
		as: 'onboarding',
		isAllowedWithoutOnboarding: true,
		hasPageTracking: true,
		isComponent: true,
	},
	'/welcome': {
		...talentDefaultRoute,
		as: 'welcome',
		isAllowedWithoutOnboarding: true,
		isAllowedWithoutVerifiedEmail: true,
		hasPageTracking: true,
		isComponent: true,
	},
	'/mailbox/:conversationId': {
		...talentDefaultRoute,
		as: 'mailbox',
		isAllowedWithoutOnboarding: true,
		isComponent: true,
		hasPageTracking: true,
		hasRocketchat: true,
	},
	'/mailbox': {
		...talentDefaultRoute,
		as: 'mailbox',
		isAllowedWithoutOnboarding: true,
		isComponent: true,
		hasPageTracking: true,
		hasRocketchat: true,
	},
	'/downloads': {
		...talentDefaultRoute,
		as: 'downloads',
		isComponent: true,
		hasRocketchat: true,
	},
	'/income': {
		...talentDefaultRoute,
		as: 'income',
		hasRocketchat: true,
	},
	'/webcamsession': {
		...talentDefaultRoute,
		as: 'webcamsession',
		isAllowedWithoutOnboarding: true,
		hasPageTracking: true,
		hasHeader: false,
		hasAsideNavigation: false,
	},
	'/dialerwork/:contractId': {
		...talentDefaultRoute,
		as: 'dialerwork',
		hasRocketchat: true,
	},
	'/outboundwork/:contractId': {
		...talentDefaultRoute,
		as: 'outboundwork',
		hasRocketchat: true,
	},
	'/outboundwork': {
		...talentDefaultRoute,
		as: 'outboundwork',
		hasRocketchat: true,
	},
	'/joboffers': {
		...talentDefaultRoute,
		as: 'joboffers',
		isComponent: true,
	},
	'/referrals': {
		...talentDefaultRoute,
		as: 'referrals',
		isComponent: true,
	},
	'/score': {
		...talentDefaultRoute,
		as: 'score',
		isComponent: true,
		hasRocketchat: true,
	},
	'/calls': {
		...talentDefaultRoute,
		as: 'calls',
		hasRocketchat: true,
	},
	'/security': {
		...talentDefaultRoute,
		as: 'security',
		isComponent: true,
	},
	'/speed': {
		...talentDefaultRoute,
		as: 'speed',
		isComponent: true,
		hasRocketchat: true,
	},
	'/supportchat': {
		...talentDefaultRoute,
		as: 'supportchat',
		isAllowedWithoutOnboarding: true,
		hasPageTracking: true,
		isComponent: true,
	},
	'/qualitychecks': {
		...talentDefaultRoute,
		as: 'qualitychecks',
		isComponent: true,
		hasRocketchat: true,
	},
	'/salestool': {
		...talentDefaultRoute,
		as: 'salestool',
		hasAsideNavigation: false,
		hasAsideSalesToolNavigation: true,
		isComponent: true,
		hooks: {
			before: async (done: (arg1: boolean) => void, match: Match) => {
				const { ydRoute } = getRouteFromMatch(match);
				const canNavigate = ydRoute && (await isSalesTool());
				setTimeout(async () => {
					if (canNavigate) {
						// eslint-disable-next-line require-atomic-updates
						ydRoute.templateClass = await importPage(ydRoute);
					}

					done(canNavigate && !!ydRoute.templateClass);
				}, 100);
			},
		},
	},
	'/adminsalestool': {
		...talentDefaultRoute,
		as: 'adminsalestool',
		hasAsideNavigation: false,
		hasAsideSalesToolNavigation: true,
		isComponent: true,
		hooks: {
			before: async (done: (arg1: boolean) => void, match: Match) => {
				const { ydRoute } = getRouteFromMatch(match);
				const canNavigate = ydRoute && (await isSalesToolAdmin());

				if (canNavigate) {
					ydRoute.templateClass = await importPage(ydRoute);
				}

				done(canNavigate && !!ydRoute.templateClass);
			},
		},
	},
	'/schedule': {
		...talentDefaultRoute,
		as: 'schedule',
		hasRocketchat: true,
	},
	'/schedule/:contractId': {
		...talentDefaultRoute,
		as: 'schedule',
		hasRocketchat: true,
	},
	'/identtsuccess': {
		as: 'identtsuccess',
		uses: (match: Match) => {
			const { queryString } = match;
			Router.navigate(`/onboarding/documents?identt=success&${queryString}`);
		},
	},
	'/help': {
		...talentDefaultRoute,
		isAllowedWithoutOnboarding: true,
		renderPage: false,
		uses: () => {
			openHelpForm();
			Router.navigate('/');
		},
		hasRocketchat: true,
	},
	'/assistant/:companyUserId/:moduleId': {
		...talentDefaultRoute,
		as: 'assistant',
		hasHeader: false,
		hasAsideNavigation: false,
		isComponent: true,
		hooks: {
			before: talentDefaultRoute.hooks?.before,
			after: () => {
				socket.kill();
			},
		},
	},
	'/assistant/:companyUserId': {
		...talentDefaultRoute,
		as: 'assistant',
		hasHeader: false,
		hasAsideNavigation: false,
		isComponent: true,
		hooks: {
			before: talentDefaultRoute.hooks?.before,
			after: () => {
				socket.kill();
			},
		},
	},
	'/assistant': {
		...talentDefaultRoute,
		as: 'assistant',
		hasHeader: false,
		hasAsideNavigation: false,
		isComponent: true,
		hooks: {
			before: talentDefaultRoute.hooks?.before,
			after: () => {
				socket.kill();
			},
		},
	},
	'/talentstats': {
		...talentDefaultRoute,
		as: 'talentstats',
		isComponent: true,
		hasRocketchat: true,
	},
	'/language-evaluation/results/:sessionId': {
		...talentDefaultRoute,
		as: 'language-evaluation',
		isAllowedWithoutOnboarding: true,
		hasPageTracking: false,
		hasHeader: false,
		hasAsideNavigation: false,
	},
	'/language-evaluation': {
		...talentDefaultRoute,
		as: 'language-evaluation',
		isAllowedWithoutOnboarding: true,
		hasPageTracking: false,
		hasHeader: false,
		hasAsideNavigation: false,
	},
	'/lang-eval-preview': {
		...talentDefaultRoute,
		as: 'languageevaluationtcom',
		isAllowedWithoutOnboarding: true,
		hasPageTracking: false,
		hasAsideNavigation: false,
		hasHeader: false,
	},
	'/sharedaccount': {
		...talentDefaultRoute,
		as: 'sharedaccount',
		isComponent: true,
	},
	'/': {
		...talentDefaultRoute,
		as: 'home',
		hasRocketchat: true,
	},
};
