import markdownit from 'markdown-it';

const htmlConverter = markdownit({
	html: false,
	linkify: false,
});

const defaultRender =
	htmlConverter.renderer.rules.link_open ||
	function (tokens, idx, options, _env, self) {
		return self.renderToken(tokens, idx, options);
	};

// eslint-disable-next-line camelcase
htmlConverter.renderer.rules.link_open = function (
	tokens,
	idx,
	options,
	env,
	self,
) {
	const aIndex = tokens[idx].attrIndex('target');
	if (aIndex < 0) {
		tokens[idx].attrPush(['target', '_blank']);
	} else if (tokens[idx].attrs) {
		tokens[idx].attrs[aIndex][1] = '_blank';
	}
	return defaultRender(tokens, idx, options, env, self);
};

export { htmlConverter };
