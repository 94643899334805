import { User } from '@app/User';
import { PageBase } from '@components/page-base';
import { globals } from '@globals';
import { html, render } from 'lit';
import { Log } from '../app/Log.js';
import { socket } from '../app/Socket.js';
import { matomoTracker } from '../app/matomoTracker';
import { checkAuth } from './routeBeforeHooks.js';
import { confirmNavigation } from './routeLeaveHooks.js';
import { getRouteFromMatch } from './utils/getRouteFromMatch';
import { isUpdateAvailable } from './utils/isUpdateAvailable';
import { Router } from './';

export const importPage = async (ydRoute: Route, { old } = {}) => {
	let TemplateClass;

	try {
		// if is for testing
		// eslint-disable-next-line no-undefined
		if (ydRoute.isComponent && old !== undefined) {
			TemplateClass = await import(`@app/pages/${ydRoute.as}.js`);
			// eslint-disable-next-line require-atomic-updates
			ydRoute.isComponent = false;
		} else {
			// crazy condition pairing because of vites limitations in interpreting dynamic import paths
			if (ydRoute.isCommonRoute && ydRoute.isComponent) {
				TemplateClass = await import(`../views/${ydRoute.as}/index.ts`);
			}
			if (!ydRoute.isCommonRoute && ydRoute.isComponent) {
				TemplateClass = await import(`@app/views/${ydRoute.as}/index.ts`);
			}
			if (ydRoute.isCommonRoute && !ydRoute.isComponent) {
				TemplateClass = await import(`@app/pages/${ydRoute.as}.js`);
			}
			if (!ydRoute.isCommonRoute && !ydRoute.isComponent) {
				TemplateClass = await import(`@app/pages/${ydRoute.as}.js`);
			}
		}
	} catch (err) {
		Log.error(err);
	}

	return TemplateClass ? TemplateClass.default : null;
};

export const commonDefaultRoute: Route = {
	as: 'home', // smart fallback?
	renderPage: true,
	hasHeader: true,
	hasAsideNavigation: true,
	isAuthRequired: true,
	isCommonRoute: false,
	isAllowedWithoutVerifiedEmail: false,
	isComponent: false,
	hasCaptcha: false,
	hasRocketchat: false,

	hooks: {
		before: async (done: (arg1: boolean) => void, match: Match) => {
			if (isUpdateAvailable()) {
				done(false);
				window.location.href = `${document.baseURI}${match.url}`;
				return;
			}
			const { ydRoute, query } = getRouteFromMatch(match);
			const canNavigate = ydRoute && checkAuth(ydRoute);

			if (canNavigate) {
				// "preload" to abort the navigation in case the imported code errors
				ydRoute.templateClass = ydRoute.renderPage
					? await importPage(ydRoute, query)
					: {};
			}

			done(canNavigate && !!ydRoute.templateClass);
		},
		after: (match: Match) => {
			const {
				ydRoute: { hasPageTracking },
			} = getRouteFromMatch(match);
			if (hasPageTracking) matomoTracker.pageTrack();

			if (globals.hasYoummdaySocket) {
				const socketAuth = User.company
					? User.company.websocket
					: User.user.websocket;

				// no token or user has switched company
				if (
					!!socket.instance &&
					(!socketAuth || (socketAuth && socket.auth !== socketAuth))
				) {
					socket.kill();

					// let socket restart by setting new auth token
					if (socketAuth) socket.auth = socketAuth;
				}

				if (socketAuth && !socket.instance) {
					socket.initAndAuthorize(socketAuth);
				}
			}
		},
		leave: (canLeave) => confirmNavigation(canLeave),
	},
	uses: async (match: Match) => {
		const { ydRoute, params, query } = getRouteFromMatch(match);
		if (!ydRoute?.renderPage || !ydRoute?.templateClass || !window.appElement) {
			return;
		}

		const [lastResolved] = Router.lastResolved() || [];
		const isReInitialiasing =
			// completely new named route
			window.appElement?.route?.as !== ydRoute.as ||
			// or url params changed
			lastResolved?.url !== match.url;

		const container = ydRoute.isComponent
			? window.appElement
			: window.appElement.mainElement.children?.page;

		if ((isReInitialiasing && ydRoute.as !== '') || ydRoute.as === 'login') {
			window.appElement.route?.template?.destroy?.();
			window.appElement.childNodes?.forEach((child) => {
				if (child.tagName?.startsWith('PAGE-')) {
					child.remove();
				}
			});
		}

		if (ydRoute.isComponent) {
			const pageComponentElement = document.createElement(
				`page-${ydRoute.as.split('/').reverse()[0]}`,
			) as PageBase;
			pageComponentElement.params = params;
			pageComponentElement.query = query;
			if (ydRoute.hasCaptcha) {
				const captchaContainer = pageComponentElement || window.appElement;
				render(
					html`
						<div
							id="captcha"
							slot="captcha"
						></div>
					`,
					captchaContainer,
				);
			}
			render(pageComponentElement, container);
			ydRoute.template = pageComponentElement;
		} else {
			const template = new ydRoute.templateClass(
				container,
				params,
				query,
				null,
				ydRoute.as,
			);
			await template.init(!isReInitialiasing); // true = no rendering (yet)

			ydRoute.template = template;
		}
		// eslint-disable-next-line require-atomic-updates
		window.appElement.route = ydRoute;
	},
};
