import { LitElement, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { globals } from '@globals';
import { RocketChatService } from '@components/services/RocketChatService.js';
import { RocketChatSocketService } from '@components/services/RocketChatSocketService.js';

import style from './style.scss?inline';
import { Model } from '@app/Model';

@customElement('yd-chat')
export default class YdChat extends LitElement {
	@state() realtimeMessages: Message[] = [];

	@state() unreadCount: number = 0;

	token = '';

	interval: ReturnType<typeof setInterval> | null = null;

	static styles = [style];

	constructor() {
		super();

		({ token: this.token } = RocketChatService.getRocketChatProperties());
	}

	async refreshToken() {
		({ token: this.token } = await RocketChatService.getAuth());
		const rcStorage = Model.Storage.get('rocketchat');
		Model.Storage.set(
			'rocketchat',
			Object.assign(rcStorage, {
				token: this.token,
			}),
		);
		return this.token;
	}

	rcOpen() {
		const rcUrl = `${globals.rocketChatApi}/home?resumeToken=${this.token}`;
		window.open(rcUrl, '_blank').focus();
	}

	hasUnreadMessages() {
		return this.realtimeMessages?.some((message) => message.unread !== 0);
	}

	connectedCallback() {
		super.connectedCallback();

		RocketChatSocketService.updateUnreadMessagesCount();
	}

	render() {
		return html`
			<div class="chat">
				<sl-button
					@click=${() => {
						this.refreshToken();
						this.rcOpen();
					}}
					variant="default"
					size="large"
					class="fab"
					circle
				>
					<iconify-icon
						id="chaticon"
						class="chaticon"
						icon="mdi:chat-bubble-outline"
						width="1.4rem"
						height="1.4rem"
					></iconify-icon>
					<iconify-icon
						id="chaticon2"
						class="chaticon"
						icon="mdi:external-link"
						width="1.4rem"
						height="1.4rem"
					></iconify-icon>
					<sl-badge
						pill
						variant="danger"
						class="indicator"
					>
						${this.unreadCount >= 99 ? '99+' : this.unreadCount}
					</sl-badge>
				</sl-button>
			</div>
		`;
	}
}
